import { useEffect, useState } from "react";
import NavBar from '../Navbar/NavBar';
import Footer from '../Footer';
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import './markdown.css';

function MarkdownPage() {
  const { filename } = useParams();
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    fetch(`/next-number/${filename}.md`)
      .then((response) => response.text())
      .then((text) => setMarkdownContent(text));
  }, [filename]);

  //return <ReactMarkdown>{markdownContent}</ReactMarkdown>;
  return (
        <div>
            <div>
                <NavBar />
            </div>
            <div id='Hello' className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container my-8 px-4 lg:px-20">

                <ReactMarkdown>{markdownContent}</ReactMarkdown>
            </div>
            </div>
            <Footer />
        </div>

    )
}

export default MarkdownPage;